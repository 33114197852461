import { useState } from "react";
import { json, redirect, type ActionArgs } from "@remix-run/node";
import {
  useNavigation,
  Form,
  useActionData,
  useLoaderData,
} from "@remix-run/react";
import {
  Page,
  Text,
  Card,
  Button,
  FormLayout,
  TextField,
  InlineError,
  Link,
} from "@shopify/polaris";

import prisma from "~/app/db.server";
import env from "~/app/env.server";
import { getShop } from "~/app/utils/shop.server";
import { authenticate, config } from "~/app/shopify.server";
import { completeOnboarding } from "../lib/api.shopify";

interface LoginJson {
  authenticator: boolean;
  expdate: string;
  has_valid_cust_device_token: boolean;
  id: number;
  sms: boolean;
  token: string;
  uid: string;
  status?: string;
}

/**
 * Redirect all traffic to merchant onboarding flow
 * TODO: redirect to a landing page / dashboard once we have one
 */
export const loader = async ({ request }) => {
  await authenticate.admin(request);

  return redirect("/app/onboarding/welcome");
};

export async function action({ request }: ActionArgs) {
  const { session } = await authenticate.admin(request);

  const formData = await request.formData();
  const requestJson = {
    username: formData.get("username"),
    password: formData.get("password"),
    isSignup: false,
  };
  const loginResponse = await fetch(`${env.ESCROW_WWW_URL}/api/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestJson),
  });
  const loginResponseJson: LoginJson = await loginResponse.json();

  if (loginResponseJson.status === "error") {
    return json({ errors: [{ message: "Invalid username or password" }] });
  }

  if (loginResponse.status !== 200) {
    return json({
      errors: [{ message: "Something went wrong. Please try again later." }],
    });
  }
  const shopName = getShop(session.shop);
  const activeShop = await prisma.shop.findFirst({
    where: { name: shopName, active: true },
  });

  if (activeShop) {
    await prisma.shop.update({
      where: { id: activeShop.id },
      data: {
        escrowCustId: loginResponseJson.id,
        escrowEmail: loginResponseJson.uid,
      },
    });
  } else {
    await prisma.shop.create({
      data: {
        name: shopName,
        escrowCustId: loginResponseJson.id,
        escrowEmail: loginResponseJson.uid,
      },
    });
  }

  // todo: handle errors
  await completeOnboarding(session);

  return redirect(
    `https://${session.shop}/services/payments_partners/gateways/${config.apiKey}/settings`,
  );
}

export default function Index() {
  const nav = useNavigation();
  const loaderData = useLoaderData<typeof loader>();

  const isLoading =
    ["loading", "submitting"].includes(nav.state) && nav.formMethod === "POST";

  const errors = useActionData<typeof action>()?.errors;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Page>
      <Card padding={{ xs: "12", sm: "16" }}>
        <Form method="post">
          <FormLayout>
            <Text as="h3" variant="headingMd">
              Enter the Escrow Account to connect to {loaderData.shop}
            </Text>
            <TextField
              type="email"
              name="username"
              label="Username"
              value={username}
              helpText="Escrow account username"
              onChange={setUsername}
              autoComplete="email"
            />
            <TextField
              type="password"
              name="password"
              label="Password"
              value={password}
              helpText="Escrow account password"
              onChange={setPassword}
              autoComplete="current-password"
            />
            {!isLoading &&
              errors &&
              errors.length > 0 &&
              errors.map((error, idx) => (
                <InlineError key={idx} fieldID="" message={error.message} />
              ))}
            <Button submit primary disabled={isLoading}>
              Link Escrow Account
            </Button>
            <Text as="p">
              Don't have an Escrow Account?{" "}
              <Link target="_blank" url={`${env.ESCROW_WWW_URL}/signup-page`}>
                Sign up
              </Link>
            </Text>
          </FormLayout>
        </Form>
      </Card>
    </Page>
  );
}
